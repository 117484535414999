"use client";

import { useState, useMemo } from "react";
import { CheckIcon } from "../../../assets/svg";
import { motion } from "framer-motion";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import jsonp from "jsonp";
import Button from "../../shared-components/Button";
import Input from "../input";

// Type definition for a pricing tier
interface Tier {
  name: string;
  id: string;
  featured: boolean;
  description: string;
  price: {
    monthly: string;
    annually: string;
  };
  highlights: string[];
  features: string[];
  buttonLabel: string;
}

const usePricingData = () => {
  const frequencies = useMemo(
    () => [
      { value: "monthly", label: "Monthly" },
      { value: "annually", label: "Annually" },
    ],
    []
  );

  const tiers: Tier[] = useMemo(
    () => [
      // {
      //   name: "Starter",
      //   id: "tier-starter",
      //   featured: false,
      //   description: "Essentials to begin",
      //   price: { monthly: "$699", annually: "$599" },
      //   highlights: ["Up to 3 Sites", "Up to 250 Assets"],
      //   features: [
      //     "Work Orders",
      //     "Asset Management",
      //     "Site Management",
      //     "User Management",
      //   ],
      //   buttonLabel: "Coming Soon",
      // },
      {
        name: "Essential plan",
        id: "tier-professional",
        featured: true,
        description: "Advanced features for growth",
        price: { monthly: "$29", annually: "$29" },
        highlights: ["Up to 7 Sites", "Up to 500 Assets"],
        features: [
          "Work Orders",
          "Asset Management",
          "Site Management",
          "User Management",
          "Purchase Orders",
        ],
        buttonLabel: "Coming Soon",
      },
      {
        name: "Enterprise",
        id: "tier-enterprise",
        featured: false,
        description: "Comprehensive solutions for scale",
        price: { monthly: "Let's talk", annually: "Let's talk" },
        highlights: ["Unlimited Sites", "Unlimited Assets"],
        features: [
          "Work Orders",
          "Asset Management",
          "Site Management",
          "User Management",
          "Purchase Orders",
        ],
        buttonLabel: "Contact Emerald Team",
      },
    ],
    []
  );

  return { frequencies, tiers };
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingSection() {
  const { frequencies, tiers } = usePricingData();
  const [frequency, setFrequency] = useState(frequencies[1]);
  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleOpen = () => {
    console.log("open");
    setOpen(true);
  };

  const toggleFrequency = () => {
    setFrequency(
      frequency.value === "monthly" ? frequencies[1] : frequencies[0]
    );
  };

  const priceDisplay = (tier: Tier) =>
    tier.price[frequency.value as "monthly" | "annually"];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!firstName || !email) {
      setError("Please fill in all fields");
      return;
    }

    setError("");

    const url =
      "https://emeralddata.us13.list-manage.com/subscribe/post?u=af2935c91e17b5478cb4670d8&amp;id=e1bb4310cc&amp;f_id=002fbbe1f0";

    jsonp(
      `${url}&EMAIL=${email}&FNAME=${firstName}`,
      { param: "c" },
      (error: any, data: { msg: string }) => {
        if (!error) {
          setIsSubmitted(true);
        }
      }
    );
    setIsSubmitted(true);
  };

  return (
    <div className="container  md:mt-20 mt-12">
      <h2 className="lg:text-4xl text-lg md:leading-9 lg:font-medium font-semibold leading-7 text-center text-gray-900 font-inter  md:pb-20 pb-12">
        Choose the plan that fits your needs.
      </h2>

      {/* Toggle for Monthly/Annually */}
      {/* <div className="flex justify-center mt-6">
        <label className="inline-flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={frequency.value === "annually"}
              onChange={toggleFrequency}
            />
            <div
              className={`
                w-10 h-5 rounded-full transition-colors duration-300
                ${
                  frequency.value === "annually"
                    ? "bg-green-500"
                    : "bg-gray-300"
                }
                 peer-checked:after:translate-x-5
                        after:content-[''] after:absolute after:top-0.5 after:left-[2px]
                        after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all
              `}
            ></div>
          </div>
          <span className="ml-2 text-xs font-medium text-gray-500">
            Save 11% Annually
          </span>
        </label>
      </div> */}

      {/* Pricing Cards */}
      <motion.div
        className="flex flex-col lg:flex-row md:gap-1 gap-6  justify-center"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        {tiers.map((tier, index) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured
                ? "bg-white backdrop-blur-md md:shadow-[0_2px_6px_rgba(0,0,0,0.04),_0_5px_18px_rgba(0,0,0,0.06),_0_24px_83px_rgba(0,0,0,0.10)] border-green-600  border-2 md:scale-105 pb-[4rem]"
                : "bg-[#EAFAF8]",
              `rounded-[1.25rem] md:px-7 px-[1.66rem] md:py-[2.38rem] py-[2.25rem] flex-1  ${
                index === 0
                  ? "md:scale-110 md:shadow-xl md:max-w-[37%] w-full "
                  : "md:max-w-[41%] w-full ml-1 "
              } `
            )}
          >
            {/* Plan Name */}
            <h3 className="text-[1.75rem] leading-[3.15425rem] text-[#111019] font-bold">
              {tier.name}
            </h3>
            <p className="text-gray-900 text-base font-[inter] font-normal">
              {tier.description}
            </p>

            {/* Price */}
            <p className="mt-[2.625rem] text-[1.75rem] font-semibold text-[#111019]">
              {priceDisplay(tier)}
              {priceDisplay(tier) !== "Let's talk" && (
                <span className="text-sm font-normal text-[#212121]">
                  {/* {frequency.value === "annually" ? "/Yearly" : "/Monthly"} */}
                  per user
                </span>
              )}
            </p>

            {/* Button */}
            <button
              onClick={index === 1 ? handleOpen : undefined}
              className="mt-7 w-full bg-green-500 text-white py-[0.88rem] px-6 rounded-lg text-sm leading-5 font-medium"
            >
              {tier.buttonLabel}
            </button>

            {/* Highlights */}
            <ul className="flex flex-col gap-3 text-sm text-gray-900 list-disc mt-[2.63rem]">
              {tier.highlights.map((highlight) => (
                <li key={highlight} className="ml-4">
                  <span>{highlight}</span>
                </li>
              ))}
            </ul>

            <hr
              className={`mt-[2.63rem] h-[2px] ${
                tier.featured ? "border-[#F1F0F3]" : "border-[#54BAA3]"
              }`}
            />

            {/* Features */}
            <div className="text-left mt-6">
              <h4 className="text-sm font-semibold text-gray-900">
                Key Features
              </h4>
              <ul className="mt-6 flex flex-col gap-4 text-base text-gray-800 font-normal">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-6 items-center">
                    <CheckIcon />
                    <p>{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </motion.div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-10"
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10  flex justify-center items-center">
          <div className="flex items-end justify-center lg:p-12 p-8 text-center sm:items-center">
            <DialogPanel
              className="relative transform overflow-auto lg:p-12 p-4 lg:pt-8 rounded-lg bg-white text-left shadow-xl transition-all max-w-[77rem] w-full"
              // style={{ height: "calc(100vh - 10rem)" }}
            >
              <div className="flex justify-end ">
                <button onClick={() => setOpen(false)}>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 font-inter"
                  />
                </button>
              </div>
              <div className="flex lg:flex-nowrap flex-wrap lg:gap-20 gap-12 justify-between rounded-xl mt-8">
                <div className="w-full">
                  <motion.div
                    initial={{ x: "-100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="w-full"
                  >
                    <h1 className="md:text-3xl text-xl leading-2 font-semibold md:leading-9 text-gray-900  w-full font-inter">
                      Transform Your Operations with
                      <span className="text-green-500">Emerald!</span>
                    </h1>
                    <p className="md:mt-3 mt-3 md:text-lg text-xs  font-normal  text-center text-gray-800 mx-auto lg:max-w-[36.8rem] w-full md:leading-7 leading-6 font-inter">
                      Join our wishlist to discover how Emerald can streamline
                      your processes. Be at the forefront of operational
                      excellence.
                    </p>

                    {/* Conditionally render form or thank you message */}
                    {!isSubmitted ? (
                      <form
                        className="flex flex-col items-center  md:mt-[2.12rem] mt-4"
                        onSubmit={handleSubmit}
                      >
                        <Input
                          label="Full Name"
                          placeholder="Full Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <Input
                          label="Email Address"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        {error && <p className="mt-4 text-red-600">{error}</p>}

                        <Button
                          variant="default"
                          iconPosition="end"
                          icon={<ArrowRightIcon />}
                          className="max-w-full text-center justify-center mt-6 md:w-[11.5rem] h-[3rem] w-[22rem]"
                          type="submit"
                        >
                          <span>Join Wish List</span>
                        </Button>
                      </form>
                    ) : (
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        className="flex flex-col items-center pb-4 mt-12"
                      >
                        <motion.div
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 1 }}
                          className="flex justify-center max-w-[400px]"
                        >
                          <iframe
                            src="https://lottie.host/embed/93e1ef98-6d9e-46bc-a211-15b7972f2beb/auzg1hhFMt.json"
                            className="w-[100px] h-[100px]"
                            title="lottieFile"
                          ></iframe>
                        </motion.div>

                        <motion.h2
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, ease: "easeOut" }}
                          className="mt-2 text-green-500 text-2xl font-semibold"
                        >
                          Thank you for joining the wishlist!
                        </motion.h2>
                      </motion.div>
                    )}
                  </motion.div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
